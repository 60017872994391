import React, { useState, useRef, useEffect } from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import { useLocation } from 'react-router-dom';

const Chat = () => {
  const [messages, setMessages] = useState([]);
  const [input, setInput] = useState('');
  const messageEndRef = useRef(null);
  const textareaRef = useRef(null);
  const containerRef = useRef(null);
  const location = useLocation();
  const currentLang = location.state?.lang || "zh"; // 默认中文

  const translations = {
    en: {
      placeholder: "Enter your prompt...",
      send: "Send",
      loading: "Generating...",
      title: "Chatter Box",
    },
    zh: {
      placeholder: "请输入提示词...",
      send: "发送",
      loading: "生成中...",
      title: "AI话匣子-免费的AI对话平台！",
    },
  };

  const text = translations[currentLang];

  useEffect(() => {
    document.title = translations[currentLang].title;
  }, [currentLang]);

  useEffect(() => {
    document.title = translations[currentLang].title;
  }, [currentLang]);

  useEffect(() => {
    const adjustGptMessageList = () => {
      document.querySelectorAll(".gpt-message li").forEach((li) => {
        // 1️⃣ 移除 li 内的多余换行符
        li.innerHTML = li.innerHTML.replace(/\n/g, "").trim();
      });
    };

    // 等待 React 渲染完成后执行
    setTimeout(adjustGptMessageList, 100);
  }, [messages]); // 监听 messages 变化，每次新消息加入时都调整样式




  const adjustTextareaHeight = () => {
    if (textareaRef.current) {
      const lineHeight = 20; // 假设每行的高度为20px
      const padding = 30; // 上下padding总和为30px
      const maxHeightDesktop = lineHeight * 10 + padding; // 10行内容的最大高度
      const maxHeightMobile = lineHeight * 4 + padding; // 4行内容的最大高度

      // 重置高度，使得在减少内容时高度可以减小
      textareaRef.current.style.height = 'auto';

      // 动态调整高度
      textareaRef.current.style.height = Math.min(
        textareaRef.current.scrollHeight,
        window.innerWidth <= 600 ? maxHeightMobile : maxHeightDesktop
      ) + 'px';
    }
  };

  useEffect(() => {
    adjustTextareaHeight(); // 调整高度
  }, [input]); // 当输入内容变化时调用

  const adjustContainerHeight = () => {
    if (containerRef.current) {
      const viewportHeight = window.innerHeight;
      containerRef.current.style.minHeight = `${viewportHeight}px`;
    }
  };
  

  useEffect(() => {
    adjustContainerHeight();
    window.addEventListener('resize', adjustContainerHeight);
    return () => {
      window.removeEventListener('resize', adjustContainerHeight);
    };
  }, []);

  const scrollToMiddle = () => {
    if (messageEndRef.current) {
      const chatWindow = document.getElementById('chatWindow');
      const halfHeight = chatWindow.clientHeight / 2;
      chatWindow.scrollTop = messageEndRef.current.offsetTop - halfHeight;
    }
  };

  useEffect(() => {
    scrollToMiddle();
  }, [messages]);

  const handleSendMessage = async () => {
    if (input) {
        const userMessage = { sender: 'user', text: input };
        const loadingMessage = { sender: 'gpt', text: text.loading };

        const newMessages = [...messages, userMessage];

        setMessages([...newMessages, loadingMessage]); // 先加入 "生成中..." 消息
        setInput('');
        // http://localhost:3001/api/chat
        // http://160.251.198.228:3001/api/chat
        // https://www.ai-chat.cc/api/chat
        try {
            const response = await fetch('https://www.ai-chat.cc/api/chat', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json'
                },
                body: JSON.stringify({ messages: newMessages }) // 发送完整对话历史
            });

            const result = await response.json();
            console.log('API response:', result);

            if (response.ok) {
                const botMessage = { sender: 'gpt', text: result.message };
                setMessages([...newMessages, botMessage]); // 替换 "生成中..." 消息
            } else {
                alert(result.message);
                setMessages(newMessages); // 移除 "生成中..." 消息
            }
        } catch (error) {
            console.error('Error:', error);
            setMessages(newMessages); // 移除 "生成中..." 消息
        }
    }
};

  return (
    <section className="chat">
      <div className="container" ref={containerRef}>
      <div id="chatWindow-scroll" className="chat-window-scroll-auto">
        <div id="chatWindow" className="chat-window">
          {messages.map((msg, index) => (
            <div key={index} className={msg.sender === 'user' ? 'user-message' : 'gpt-message'}>
              {msg.sender === 'user' ? (
                msg.text
              ) : (
                <div className="gpt-message-container">
                  <div className="gpt-avatar">
                    <img src="/chatgpt-avatar.png" alt="ChatGPT Avatar" width="24" height="24" />
                  </div>
                  <div className="gpt-text">
                    <ReactMarkdown remarkPlugins={[remarkGfm]}>{msg.text}</ReactMarkdown>
                  </div>
                </div>
              )}
              {index === messages.length - 1 && <div ref={messageEndRef} />}
            </div>
          ))}
        </div>
      </div>
        <div className="chat-input-container">
          <textarea
            id="messageInput"
            value={input}
            ref={textareaRef}
            onChange={(e) => setInput(e.target.value)}
            placeholder={text.placeholder}
            data-gramm="false"
            rows="1"
          ></textarea>
          <div className="chat-input-button">
            <button
              id="sendMessageButton"
              onClick={handleSendMessage}
              disabled={!input.trim()} // Disable button if input is empty or only spaces
              style={{ backgroundColor: input.trim() ? 'rgb(21, 169, 13)' : '#c0c0c0' }}
            >
              {text.send}
            </button>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Chat;
