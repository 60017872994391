import React from "react";
import "./Terms.css"; // 确保正确引入样式

const Terms = () => {
  return (
    <div className="terms-container">
      <h2>服务条款</h2>
      <p>欢迎使用本服务，请仔细阅读以下服务条款。</p>
      <h3>1. 账户注册</h3>
      <p>您需要提供有效的电子邮件地址和密码来注册账户。</p>
      <h3>2. 许可</h3>
      <p>您可以在合法的范围内使用我们的服务。</p>
      <a href="/">返回主页</a>
    </div>
  );
};

export default Terms;
