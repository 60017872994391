import React from "react";
import "./Privacy.css"; // 确保正确引入样式

const Privacy = () => {
  return (
    <div className="privacy-container">
      <h2>隐私政策</h2>
      <p>我们尊重您的隐私，以下是我们的隐私政策。</p>
      <h3>1. 我们收集的信息</h3>
      <p>我们可能会收集您的个人信息，例如姓名、电子邮件地址等。</p>
      <h3>2. 信息使用</h3>
      <p>我们不会未经允许分享您的个人信息。</p>
      <a href="/">返回主页</a>
    </div>
  );
};

export default Privacy;
