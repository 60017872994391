import React from 'react';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import Header from './components/Header.js';
import Services from './components/Services.js';
import Chat from './components/Chat.js';
import Home from './components/Home.js';
import Terms from "./components/Terms";
import Privacy from "./components/Privacy";
import Footer from './components/Footer.js';
import './chat.css'; // 在外层文件中导入 CSS 文件

// function App() {
//   return (
//     <div>

//       <main>
//         <Chat />
//       </main>
//     </div>
//   );
// }

// export default App;

const App = () => {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/chat" element={<Chat />} />
        <Route path="/terms" element={<Terms />} />
        <Route path="/privacy" element={<Privacy />} />
      </Routes>
    </Router>
  );
};

export default App;
