import React, { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import "./Home.css";

const translations = {
  en: {
    logoText: "Chatter Box",
    title: "Chatter Box",
    subtitle: "Free AI Chat Platform",
    feature1: "Write Articles",
    feature2: "Write Code",
    feature3: "Psychological Counseling",
    feature4: "Legal Consultation",
    feature5: "AI Virtual Companion",
    startChat: "Start Chatting",
    connecting: "Connecting...",
    terms: "By using this service, you agree to our",
    termsLink: "Terms of Service",
    and: "and",
    privacyLink: "Privacy Policy",
    copyright: "© 2025 Chatter Box AI - All rights reserved",
    tagline: "AI chat, it's that simple",
    bubble1: "Hi!",
    bubble2: "How can I help you?",
    bubble3: "Let's chat!",
  },
  zh: {
    logoText: "Chatter Box",
    title: "AI话匣子-免费的AI对话平台！",
    subtitle: "免费的AI聊天平台",
    feature1: "写文章",
    feature2: "写代码",
    feature3: "心理咨询",
    feature4: "法律咨询",
    feature5: "AI虚拟恋人",
    startChat: "开始聊天",
    connecting: "正在连接...",
    terms: "使用即表示您同意我们的",
    termsLink: "服务条款",
    and: "和",
    privacyLink: "隐私政策",
    copyright: "© 2025 Chatter Box AI话匣子 - 版权所有",
    tagline: "AI聊天，就是这么简单",
    bubble1: "Hi!",
    bubble2: "有什么可以帮你?",
    bubble3: "让我们聊天吧!",
  },
};

const Home = () => {
  const [isLoading, setIsLoading] = useState(false);
  const [currentLang, setCurrentLang] = useState("zh"); // 默认中文
  const navigate = useNavigate();

  useEffect(() => {
    const detectLanguage = () => {
      const savedLang = localStorage.getItem("preferredLanguage");
      const systemLocale = Intl.DateTimeFormat().resolvedOptions().locale;
      const browserLang = navigator.language;
  
      console.log("LocalStorage preferredLanguage:", savedLang);
      console.log("System locale:", systemLocale);
      console.log("Navigator language:", browserLang);
  
      // **如果 localStorage 里有存的语言，直接使用**
      if (savedLang) {
        if (savedLang === "en" && systemLocale.startsWith("zh")) {
          console.log("系统是中文，但 localStorage 记录了英文，默认中文");
          setCurrentLang("zh");
          localStorage.setItem("preferredLanguage", "zh"); // 强制更新为中文
        } else {
          setCurrentLang(savedLang);
        }
        return;
      }
  
      // **如果 localStorage 没有记录，完全按照操作系统语言**
      if (browserLang.startsWith("zh") || systemLocale.startsWith("zh")) {
        setCurrentLang("zh");
      } else {
        setCurrentLang("en");
      }
    };
  
    detectLanguage();
  }, []);
  
  
  useEffect(() => {
    document.title = translations[currentLang].title;
  }, [currentLang]);
  

  useEffect(() => {
    localStorage.setItem("preferredLanguage", currentLang);
  }, [currentLang]);

  const toggleLanguage = () => {
    const newLang = currentLang === "zh" ? "en" : "zh";
    setCurrentLang(newLang);
  };

  const text = translations[currentLang];

  const handleStartChat = () => {
    setIsLoading(true);
    setTimeout(() => {
      setIsLoading(false);
      navigate("/chat", { state: { lang: currentLang } }); // 传递语言状态
    }, 800);
  };
  

  return (
    <div className="home-container">
    <div className="container">
      {/* <div
        className="language-switch"
        onClick={toggleLanguage}
        style={{
          position: "absolute",
          top: "20px",
          right: "20px",
          cursor: "pointer",
          padding: "5px 10px",
          borderRadius: "5px",
          backgroundColor: "#f0f0f0",
          fontSize: "14px",
        }}
      >
        {currentLang === "zh" ? "English" : "中文"}
      </div> */}

      <div className="card">
        <div className="logo">
          <span className="logo-icon">💬</span>
          <span className="logo-text">{text.logoText}</span>
        </div>

        <div className="header">
          {/* <h1>{text.title}</h1> */}
          <p className="subtitle">{text.subtitle}</p>
        </div>

        <div className="features">
          <div className="feature-item">
            <div className="feature-icon">✓</div>
            <div className="feature-text">{text.feature1}</div>
          </div>
          <div className="feature-item">
            <div className="feature-icon">✓</div>
            <div className="feature-text">{text.feature2}</div>
          </div>
          <div className="feature-item">
            <div className="feature-icon">✓</div>
            <div className="feature-text">{text.feature3}</div>
          </div>
          <div className="feature-item">
            <div className="feature-icon">✓</div>
            <div className="feature-text">{text.feature4}</div>
          </div>
          <div className="feature-item">
            <div className="feature-icon">✓</div>
            <div className="feature-text">{text.feature5}</div>
          </div>
        </div>

        <div className="button-section">
          <button className="chat-button" onClick={handleStartChat} disabled={isLoading}>
            {isLoading ? (
              <>
                <span className="loading-spinner"></span>
                {text.connecting}
              </>
            ) : (
              text.startChat
            )}
          </button>
        </div>

        {/* <div className="chat-bubble chat-bubble-1">{text.bubble1}</div> */}
        {/* <div className="chat-bubble chat-bubble-2">{text.bubble2}</div> */}
        {/* <div className="chat-bubble chat-bubble-3">{text.bubble3}</div> */}

        <div className="terms">
          <p>
            {text.terms}
            <a href="/terms">{text.termsLink}</a>
            {text.and}
            <a href="/privacy">{text.privacyLink}</a>
          </p>
        </div>
      </div>

      <footer className="footer">
        <div className="footer-content">
          <p>{text.copyright}</p>
          <p className="footer-tagline">{text.tagline}</p>
        </div>
      </footer>
    </div>
    </div>
  );
};

export default Home;
